define(
  ({
    app_name: 'Elastic',
    none: '無',
    select: '選擇…',
    cancel: '取消',
    drop_ship_instructions: '只限國內貨運。不能寄運至陸軍或海軍郵政局。',
    available_as_of: '<date />起有存貨',
    product_data_sheet_tab_technology: '科技',
    product_data_sheet_tab_overview: '綜述',
    product_data_sheet_tab_details: '細節',
    cart_submit_order_dealer: '下訂單',
    cart_submit_order_rep: '下訂單',
    cart_submit_order: '提交訂單',
    erp_order_number: 'ERP訂單號碼',
    sap_order_number: 'SAP號碼訂單',
    nda_explanation: 'NDA釋義',
    terms_and_conditions_description: '點擊下方以表示你已閱讀並了解<a href="###" target="_blank">條件與條款</a>',
    discounted_total: '折扣後總和',
    upcharge: '附加費',
    dont_show_me_this_again: '不要再對我顯示',
    okay: 'OK',
    arrival_date: '開始貨運日期',
    sso_message: '外部登入',
    alt_login_title: '登入',
    prebook: '預訂',
    ats: 'ATS',
    alternate_images: '替代影像',
    choose: '選擇',
    new_version_available_modal: '有新版本可用',
    new_version_available_message: '應用程式有新版本可用',
    new_version_reload: '載入新版本',
    new_version_snooze: '稍後提醒我',
    new_version_save_and_reload: '儲存我的工作並重新載入',
    popup_may_be_suppressed_title: '已開啟付款頁',
    popup_may_be_suppressed_description: '信用卡付款頁已開啟。如你不見該頁，請查看瀏覽器有否被阻擋之彈出視窗並允許彈出。',
    popup_blocked_title: '彈出被阻',
    popup_blocked_description: '新付款視窗被阻。請查看你的瀏覽器設定及插件並再試。如問題持續，你可儲存你的訂單並以另一瀏覽器開啟彈性版本。彈性版本最適宜於最近版本之Google Chrome開啟。',
    pay_by_credit_card: '以信用卡付款',
    do_you_want_to_pay_by_credit_card: '你希望以信用卡付款嗎？',
    pay_by_credit_card_no: '否',
    pay_by_credit_card_yes: '是',
    cancel_order_submission_and_payment: '取消提交訂單及付款',
    product_reference_short: '參考號碼：%{reference}',
    product_reference_long: '上年度SKU：%{reference}',
    variation_code: '變化編號',
    pending_orders: '待定訂單',
    freight_discount_message: '此貨件中的商品符合「%{sales_program}」貨運折扣條件',
    partial_freight_discount_message: '對於這批貨件中不符合「%{sales_program}」貨運折扣條件的商品，請選擇其中一種貨運方式',
    non_qualifying_items_ship_by_default_method: '任何不符合「%{sales_program}」條件的商品將使用預設方式發貨',
    export_pdf: '匯出 PDF',
    ots: 'OTS',
    terms_and_conditions: '條款與細則',
    accept: '接受',
    back_to_orders: '返回訂單',
    back_to_payments: '返回付款',
    back_to_shipments: '返回貨運',
    required: '所需',
    send_request: '發送請求',
    should_not_empty: '不應留空',
    should_be_number: '應為數字',
    incorrect_value: '數值不正確',
    incorrect_phone: '電話號碼必須與國際格式 +xx 相符',
    board_riders_description: 'Boardriders, Inc. 是一家領先的極限運動和生活時尚品牌企業，為世界各地的 Boardriders 設計、生產和分銷品牌服裝、鞋類和配飾。本公司的服裝和鞋類品牌為崇尚戶外極限運動的年輕人提供休閒生活時尚選擇。',
    region_error_message: '需要選擇地區',
    country_error_message: '需要選擇國家',
    state_error_message: '需要選擇州 / 省',
    config_error_message: '由於欠缺管理設定，開啟表格時因而遇到問題。',
    success: '成功',
    error: '遇到問題',
    invalid_phone_number: '電話號碼不正確',
    invalid_email: '電郵地址不正確',
    submit: '提交',
    success_message: '已成功提交你的資訊',
    error_message: '提交資訊時遇到問題。如問題持續，請再試一次或直接聯絡支援團隊。',
    no_schema: '未設定此功能',
    reset: '重設',
    quick_entry_table_header_shipment_b2c: '貨運',
    enter_quantities_segmented_control_b2c_by_shipment: '依貨運',
    new_document_b2c_shipment: '貨運',
    boxed_order: '裝箱訂單',
    boxed_order_explanation_title: '裝箱訂單',
    boxed_order_explanation: '如新增超過一個裝箱至訂單，則會被標示為累計數量。所輸入的數量會在裝箱的 # 中重複出現。',
    boxes: '裝箱',
    units_per_box: '每箱單位',
    regional: '地區範圍',
    purchase_order_tooltip: '所輸入的數值無效。',
    tournament_date_is_too_late: '日期必須為%{date}或之前',
    tournament_date_is_too_early: '日期必須為%{date}或之後',
    page_notes_error: '所輸入的數值無效。',
    order_name_not_valid: '所輸入的數值無效。',
    internet_connection_lost: '未能連線上網',
    internet_connection_lost_builder_description: '<p>你目前未能連線上網，必須先重新連接才可繼續。系統已保留你最近一次的手動或自動儲存記錄。</p><p>如果你擔心資料意外遺失，請按下方按鈕下載備份資料檔案，並將檔案轉寄到 <Link> support@elasticsuite.com</Link> 恢復訂單。</p>',
    internet_connection_lost_description: '你目前未能連線上網，必須先重新連接才可繼續。',
    download_backup_data_file: '下載備份資料檔案',
    invalid_session: '連線服務無效',
    invalid_session_builder_description: '<p>你目前未能連線上網，必須先重新連接才可繼續。系統已保留你最近一次的手動或自動儲存記錄。</p><p>如果你擔心資料意外遺失，請按下方按鈕下載備份資料檔案，並將檔案轉寄到 <Link> support@elasticsuite.com</Link> 恢復訂單。</p>',
    invalid_session_description: '你目前未能連線上網，必須先重新連接才可繼續。',
    submit_order_description: '你的訂單即將提交。一經提交，訂單將鎖上並無法再編輯。請注意訂單將儲存至雲端。',
    all_sells_final: '所有活動訂單均為最終訂單，不符合退貨條件。請確保你在提交之前已檢查訂單。感謝你的支持！',
    invalid_date: '日期無效',
    date_placeholder: '月月-日日-年年年年',
  }),
);
