define(
  ({
    app_name: 'Elastik',
    none: 'Yok',
    select: 'seç...',
    cancel: 'İptal et',
    drop_ship_instructions: "Sadece yurtiçi gönderim. APO'lara ya da FPO'lara gönderim yok.",
    product_data_sheet_tab_technology: 'Teknoloji',
    product_data_sheet_tab_overview: 'Genel Bakış',
    product_data_sheet_tab_details: 'Ayrıntılar',
    cart_submit_order_dealer: 'Sipariş Ver',
    available_as_of: '<date /> tarihi itibarı ile müsait',
    cart_submit_order_rep: 'Sipariş Ver',
    cart_submit_order: 'Siparişi Gönder',
    erp_order_number: 'ERP Sipariş #',
    sap_order_number: 'SAP Sipariş #',
    nda_explanation: 'NDA açıklaması.',
    terms_and_conditions_description: '<a href="###" target="_blank">Şartlar ve Koşullarımızı</a> okuduğunu belirtmek için aşağıyı işaretle',
    discounted_total: 'İndirimli Toplam',
    upcharge: 'Alınan Ücret',
    dont_show_me_this_again: 'Bunu bana tekrar gösterme.',
    okay: 'Tamam',
    arrival_date: 'Başlangıç Gönderme Tarihi',
    sso_message: 'Harici oturum açma',
    alt_login_title: 'Oturum aç',
    prebook: 'Prebook',
    ats: 'ATS',
    alternate_images: 'Alternatif Görüntüler',
    choose: 'Seç',
    new_version_available_modal: 'Yeni Sürüm Mevcut',
    new_version_available_message: 'Uygulamanın yeni bir sürümü mevcut',
    new_version_reload: 'Yeni sürümü yükle',
    new_version_snooze: 'Daha sonra hatırlat',
    new_version_save_and_reload: 'Çalışmamı kaydet ve tekrar yükle',
    popup_may_be_suppressed_title: 'Ödeme Sayfası Açıldı',
    popup_may_be_suppressed_description: 'Bir kredi kartı ödeme sayfası açıldı. Onu görmüyorsan, engellenen bir açılan pencere için internet tarayıcını kontrol et ve ona izin ver.',
    popup_blocked_title: 'Açılan Pencere Engellendi',
    popup_blocked_description: "Ödeme gönderme için yeni açılan bir pencere engellendi. İnternet tarayıcının ayarlarını ve uzantıları kontrol et ve tekrar dene. Sorun devam ederse, siparişini kaydedebilir ve Elastic'i bir başka internet tarayıcısında açabilirsin. Elastic en iyi, Google Chrome'un son sürümlerini destekler.",
    pay_by_credit_card: 'Kredi Kartı İle Öde',
    do_you_want_to_pay_by_credit_card: 'Kredi kartı ile ödemek istiyor musun?',
    pay_by_credit_card_no: 'Hayır',
    pay_by_credit_card_yes: 'Evet',
    cancel_order_submission_and_payment: 'Sipariş gönderimini ve ödemeyi iptal et',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'Önceki Yıl SKU: %{reference}',
    variation_code: 'Varyasyon kodu',
    pending_orders: 'Bekleyen Siparişler',
    freight_discount_message: 'Bu gönderideki ürünler %{sales_program} kargo indirimi için uygundur',
    partial_freight_discount_message: 'Bu gönderideki %{sales_program} kargo indirimi için uygun olmayan ürünler için bir kargo yöntemi seçin',
    non_qualifying_items_ship_by_default_method: '%{sales_program} için uygun olmayan öğeler varsayılan yöntemle gönderilir',
    export_pdf: "PDF'yi dışa aktar",
    ots: 'OTS',
    terms_and_conditions: 'Şartlar ve Koşullar',
    accept: 'Kabul et',
    back_to_orders: 'Siparişlere Geri Dön',
    back_to_payments: 'Ödemelere Geri Dön',
    back_to_shipments: 'Gönderilere Geri Dön',
    required: 'Gerekli',
    send_request: 'talep gönder',
    should_not_empty: 'boş olmamalıdır',
    should_be_number: 'bir sayı olmalıdır',
    incorrect_value: 'yanlış değer',
    incorrect_phone: 'telefon numarası uluslararası formatla +xx eşleşmelidir',
    board_riders_description: 'Boardriders, Inc., dünya çapında Boardriders için markalı giysi, ayakkabı ve aksesuar tasarlayan, üreten ve dağıtan lider bir aksiyon sporları ve yaşam tarzı şirketidir. Şirket giyim ve ayakkabı markaları, açık hava aksiyon sporları tutkusundan ilham alan genç fikirli insanlar için rahat bir yaşam tarzını temsil etmektedir.',
    region_error_message: 'bölge seçilmeli',
    country_error_message: 'ülke seçilmeli',
    state_error_message: 'eyalet/şehir seçilmeli',
    config_error_message: 'Eksik yönetim ayarları nedeniyle bu formu açarken bir hata oluştu.',
    success: 'Başarılı',
    error: 'Hata',
    invalid_phone_number: 'Geçersiz telefon numarası',
    invalid_email: 'Geçersiz e-posta',
    submit: 'Gönder',
    success_message: 'Bilgilerin başarılı bir şekilde gönderildi',
    error_message: 'Bilgilerin gönderilirken bir hata oluştu. Tekrar dene ya da sorun devam ederse destekle iletişim kur.',
    no_schema: 'Bu özellik henüz yapılandırılmadı',
    reset: 'Sıfırla',
    quick_entry_table_header_shipment_b2c: 'Gönderim',
    enter_quantities_segmented_control_b2c_by_shipment: 'Gönderime Göre',
    new_document_b2c_shipment: 'Gönderim',
    boxed_order: 'Kutulanmış Sipariş',
    boxed_order_explanation_title: 'Kutulanmış Siparişler',
    boxed_order_explanation: 'Bir siparişe birden fazla kutu eklemek miktar çarpanı görevi görür. Girilen miktarlar girilen kutu sayısı kadar çoğaltılacaktır.',
    boxes: 'Kutular',
    units_per_box: 'Kutu Başına Birim',
    regional: 'Bölgesel',
    purchase_order_tooltip: 'Girilen değer geçerli değil.',
    tournament_date_is_too_late: 'Tarih %{date} tarihinde veya öncesinde olmalıdır',
    tournament_date_is_too_early: 'Tarih %{date} tarihinde veya sonrasında olmalıdır',
    page_notes_error: 'Girilen değer geçerli değil.',
    order_name_not_valid: 'Girilen değer geçerli değil.',
    internet_connection_lost: 'İnternet Bağlantısı Koptu',
    internet_connection_lost_builder_description: '<p>İnternete bağlı değilsin. Devam etmek için lütfen yeniden bağlan. Çalışman son manuel veya otomatik kaydetmeyle kaydedildi.</p><p>Veri kaybı konusunda endişelerin varsa, lütfen aşağıdaki düğmeyi tıklayarak yedekleme veri dosyasını indir ve siparişini eski durumuna getirmek için bu dosyayı <Link>support@elasticsuite.com</Link> adresine ilet.</p>',
    internet_connection_lost_description: 'Şu anda internete bağlı değilsin. Devam etmek için lütfen yeniden bağlan.',
    download_backup_data_file: 'Yedekleme Veri Dosyasını İndir',
    invalid_session: 'Geçersiz Oturum',
    invalid_session_builder_description: '<p>İnternete bağlı değilsin. Devam etmek için lütfen yeniden bağlan. Çalışman son manuel veya otomatik kaydetmeyle kaydedildi.</p><p>Veri kaybı konusunda endişelerin varsa, lütfen aşağıdaki düğmeyi tıklayarak yedekleme veri dosyasını indir ve siparişini eski durumuna getirmek için bu dosyayı <Link>support@elasticsuite.com</Link> adresine ilet.</p>',
    invalid_session_description: 'Şu anda internete bağlı değilsin. Devam etmek için lütfen yeniden bağlan.',
    submit_order_description: 'Siparişin gönderilmek üzere. Gönderildiğinde kilitlenecek ve artık düzenlenemeyecektir. Siparişin buluta kaydedileceğini lütfen unutma.',
    all_sells_final: 'Tüm etkinlik siparişleri kesindir ve iade edilemez. Lütfen siparişini göndermeden önce incelediğinden emin ol. Desteğin için teşekkürler!',
    invalid_date: 'Geçersiz Tarih',
    date_placeholder: 'AA-GG-YYYY',
  }),
);
